import { Box, Button, ButtonProps, CircularProgress, Grid, GridProps, Slide, Typography, alpha, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, RefObject, useEffect, useMemo, useState } from 'react';
import { PickByPropertyType } from './types';

export const sample = <T,>( arr: T[], n: number ): T[] => {
  if( !n || arr.length <= n ) return arr;
  const x = Math.floor( arr.length / n );
  if( x > 1 ) {
    return sample( arr.filter( ( _a, idx ) => idx % x == 0 ), n );
  }
  const y = Math.ceil( arr.length / ( arr.length - n ) );
  const b = arr.filter( ( _a, idx ) => idx % y != 0 );
  return [ ...b.slice( 0, n - 2 ), ...b.slice( -2 ) ];
}


export interface ScheduleSectionProps<T extends string | Record<string, unknown>> {
  enable?: boolean;
  header: string;
  sectionRef: RefObject<HTMLDivElement>;
  choices: T[] | undefined;
  labelField?: keyof PickByPropertyType<T, string>;
  label?: ( v: T ) => string;
  sampleSize?: number;
  onSelect: ( v: T ) => void;
  isSelected: ( v: T ) => boolean;
  buttonSx?: ButtonProps[ 'sx' ];
  containerProps?: Partial<GridProps>;
  itemProps?: Partial<GridProps>;
}

export const ScheduleSection = <T extends string | Record<string, unknown>>( props: ScheduleSectionProps<T> ): ReactElement | null => {
  const { enable = true, header, sectionRef, choices, sampleSize = 0, onSelect, isSelected, labelField, label,
    buttonSx = {}, itemProps = {}, containerProps = {},
  } = props;
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const isSmall = useMediaQuery( theme.breakpoints.down( 'md' ) );
  const isMedium = useMediaQuery( theme.breakpoints.down( 'lg' ) );
  const [ more, setMore ] = useState( false );
  const sampled = useMemo( () => sample( choices || [], sampleSize ), [ choices ] );

  useEffect( () => {
    if( more ) setMore( false );
  }, [ choices ] );

  const getLabel = ( choice: T ): string => {
    if( labelField ) return ( choice[ labelField ] || labelField ) as string;
    return label ? label( choice ) : 'label';
  };

  if( !enable ) return null;

  return (
    <>
      <Box
        ref={sectionRef}
        sx={{
          // border: '1px solid red',
          margin: '2rem auto 0',
          '& .MuiTypography-body1': {
            // color: 'red',
            margin: '1.25rem 1rem 1rem 0',
            fontWeight: 'bold',
            width: '30rem',
            display: 'inline-block',
          },
        }}
      >
        {choices === undefined
          ? (
            <Box pt={2}>
              <CircularProgress size='2rem' />
              {/* <Typography> Loading...</Typography> */}
            </Box>
          )
          : (
            <Typography
              component='span'
              sx={{
              }}
              align='center'
            >
              {header}
            </Typography>
          )}
      </Box>

      <Slide in={!!enable && choices !== undefined} direction='up' >
        <Grid container
          alignItems='flex-start'
          sx={{
            // height: '22rem',
            // border: '1px solid purple',
            '& .MuiButton-outlined': {
              backgroundColor: alpha( theme.palette.primary.main, 0.08 ),
              color: theme.palette.primary.main,
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
              },
              '&:hover': {
                backgroundColor: alpha( theme.palette.primary.main, 0.18 ),
              },
            }
          }}
          {...containerProps}
        >
          {!choices?.length
            ? (
              <Typography pt={2}>No available choices</Typography>
            )
            : ( more ? choices : sampled ).map( ( choice, idx ) => (
              <Grid
                item
                key={idx}
                xs={12} sm={'auto'}
                {...itemProps}
              >

                <Button
                  variant={isSelected( choice ) ? 'contained' : 'outlined'}
                  size='small'
                  onClick={() =>
                    onSelect( choice )
                  }
                  sx={{
                    margin: { xs: '0.5rem 0', sm: '0.5rem' },
                    width: { xs: '100%', sm: '10rem', md: '14rem' },
                    '& .MuiTypography-root': {
                      margin: 'inherit',
                    },
                    ...buttonSx
                  }}
                  disableRipple
                >
                  <Typography
                    component='span'
                    sx={{
                      margin: '0.5rem 1rem 0 0',
                    }}>
                    {getLabel( choice )}
                  </Typography>
                </Button>

              </Grid>
            ) )
          }
          {choices && choices.length > sampled.length &&
            <Grid item key='more'
              {...itemProps}

            >
              <Button
                variant='text'
                size='medium'
                onClick={() => setMore( !more )}
                sx={{
                  margin: '0.5em',
                  width: { xs: '12rem', sm: '10rem', md: '9rem' },
                  // '& .MuiTypography-root': {
                  //   margin: 'inherit',
                  // },
                  ...buttonSx
                }}
                disableRipple
              >
                <Typography component='span' sx={{ margin: '0.5rem 1rem 0 0' }}>
                  {more ? 'less' : 'more'}
                </Typography>
              </Button>
            </Grid>
          }

        </Grid>
      </Slide >
    </>
  );
}





