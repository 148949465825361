import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { uniqBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams, useRevalidator } from 'react-router-dom';
import { AppointmentContext, parseableDateTz, prettyDate, prettyShortDate, prettyTime, scrollDown } from './AppointmentContainer';
import { ScheduleSection, sample } from './ScheduleSection';
import { ConfirmDialog } from './components/ConfirmDialog';
import { AppointmentRescheduleSlot, getAppointmentRescheduleSlots, rescheduleAppointment } from './profiles';

export { sample };

export interface ProfileScheduleSlot extends Record<string, unknown> {
  id: string;
  // schedule: {
  //   display: string;
  // },
  status: string;
  start: string;
  end: string;
  location: string;
  practitioner: string;
  // appointmentTypeCode: string;
  code: string;
  display: string;
}

export interface AppointmentType extends Record<string, unknown> {
  id: string;
  name: string;
  label?: string;
  newPatient?: boolean;
  dayAvailables?: Record<string, unknown>[];
}


export const AppointmentReschedule: FC = () => {
  const { appointment, isXSmall, setError, setNotify, setStartTime, setStatus, fontHeader } = useOutletContext() as AppointmentContext;
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const sectionSlots = useRef<HTMLDivElement>( null );
  const sectionDays = useRef<HTMLDivElement>( null );
  const sectionTimes = useRef<HTMLDivElement>( null );
  // loader: async ( { params } ) => getAppointmentRescheduleSlots( params.appointmentId ),
  // const slots = useRouteLoaderData( 'appointment-reschedule' ) as AppointmentRescheduleSlot[];
  const [ slots, setSlots ] = useState<AppointmentRescheduleSlot[] | undefined>();

  useEffect( () => {
    ( async () => {
      const _slots = await getAppointmentRescheduleSlots( appointmentId );
      setSlots( _slots );
    } )();
  }, [] );

  const [ openRescheduleDialog, setOpenRescheduleDialog ] = useState<AppointmentRescheduleSlot | undefined>();

  const { timeZoneName } = appointment;
  const [ date, setDate ] = useState<Date | undefined>();

  const isDaySelectedDate = useCallback( ( day: string ) => day == parseableDateTz( date, timeZoneName ), [ date, timeZoneName ] );

  type ShowTimes = 'Morning' | 'Afternoon' | 'Any Time';
  const allShowTimes: ShowTimes[] = [ 'Morning', 'Afternoon', 'Any Time' ]
  const [ showTimes, setShowTimes ] = useState<ShowTimes | undefined>();

  useEffect( () => scrollDown( sectionSlots ) );

  const onClickShowTimes = useCallback( ( newShowTimes: ShowTimes ) => {
    setShowTimes( showTimes == newShowTimes ? undefined : newShowTimes )
    setDate( undefined );
    scrollDown( sectionDays );
  }, [ sectionDays.current?.offsetTop, showTimes, setDate ] );

  const onClickDate = useCallback( ( d: string ) => {
    setDate( new Date( d ) );
    scrollDown( sectionTimes );
  }, [ sectionTimes.current?.offsetTop, showTimes, setDate ] );

  const isMorning = ( d: string ): boolean => prettyTime( d, timeZoneName ).includes( 'AM' );

  type AvailableDate = { label: string; day: string; }
  const availableDates = useMemo<AvailableDate[]>( () => {
    if( !slots ) return [];
    const available = slots
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
      .map( slot => ( { label: prettyShortDate( slot.start, timeZoneName ), day: parseableDateTz( slot.start, timeZoneName ) } ) );
    return uniqBy( available, a => a.label );
  }, [ slots, showTimes ] );

  const filteredSlots = useMemo( () => {
    if( !slots || !date || !showTimes ) return;
    const day = prettyDate( date, timeZoneName );
    const available = slots
      .filter( slot => prettyDate( slot.start, timeZoneName ) == day )
      .filter( slot => showTimes == 'Any Time' || ( showTimes == 'Morning' ? isMorning( slot.start ) : !isMorning( slot.start ) ) )
    return available;
  }, [ slots, date, showTimes, timeZoneName ] );

  const onReschedule = useCallback( async ( slot: AppointmentRescheduleSlot ) => {
    const errors = await rescheduleAppointment( appointment.id, slot.start, slot.end );
    if( errors ) {
      setError( errors[ 0 ] );
      return;
    }
    setStatus( 'booked' );
    setStartTime( slot.start );
    setNotify( 'Your appointment has been rescheduled.' );
    revalidator.revalidate();
    navigate( '../..' ); // Was just "..", but update/add-new means sometimes the wrong data/breadcrumb
  }, [ navigate, appointment ] );


  return (
    <>
      <h3
        style={{
          fontFamily: fontHeader,
        }}
      >
        Appointment Reschedule
      </h3>
      <div id='appointment-reschedule'>

        <Stack mb={2}

        >
          {slots === undefined &&
            <Box
              sx={{
                textAlign: 'center',
                // border: '1px solid purple',
                // width: '100%'
              }}
            >
              <CircularProgress size='3rem' />
            </Box>
          }
          {slots?.length &&
            <Box
              sx={{
                textAlign: 'center',
                // border: '1px solid purple',
                // width: '100%'
              }}
            >

              {/***********  Time Slots *****************/}

              <ScheduleSection<ShowTimes>
                sectionRef={sectionSlots}
                header={'What is your preferred time of day?'}
                choices={allShowTimes}
                label={v => v}
                sampleSize={0}
                onSelect={onClickShowTimes}
                isSelected={( v ) => v == showTimes}
                containerProps={{
                  justifyContent: 'space-between',
                  columnSpacing: 2,
                }}
                itemProps={{
                  xs: 12,
                  sm: Math.floor( 12 / allShowTimes.length ),
                }}
                buttonSx={{
                  width: {
                    xs: '100%', sm: '100%'
                  },
                  // '& .MuiTypography-root': {
                  //   margin: 'inherit',
                  // },
                }
                }

              />





              {/***********  Dates *****************/}

              < ScheduleSection<AvailableDate>
                enable={!!showTimes}
                sectionRef={sectionDays}
                header={`Available "${ showTimes }" Dates`}
                choices={availableDates}
                labelField='label'
                // itemProps={{
                //   sm: 3,
                // }}
                buttonSx={{
                  width: { xs: '100%', sm: '10rem' },
                }}
                sampleSize={13}
                onSelect={( v ) =>
                  onClickDate( v.day )
                }
                isSelected={( v ) => isDaySelectedDate( v.day )}
              />


              {/***********  Times *****************/}
              <ScheduleSection<AppointmentRescheduleSlot>
                enable={!!date}
                sectionRef={sectionTimes}
                header={`Available Times for ${ prettyDate( date, timeZoneName ) }`}
                choices={filteredSlots}
                label={v => prettyTime( v.start, timeZoneName )}
                sampleSize={13}
                onSelect={slot => setOpenRescheduleDialog( slot )}
                // isSelected={( v ) => v.id == slot?.id}
                isSelected={_v => false}
                buttonSx={{ width: { xs: '100%', sm: '10rem', md: '9rem' } }}
              />

              <ConfirmDialog
                open={!!openRescheduleDialog}
                title={'Book appointment'}
                message={`Do you want to book the appointment for ${ prettyTime( openRescheduleDialog?.start, timeZoneName ) } on ${ prettyDate( openRescheduleDialog?.start, timeZoneName ) } ?`}
                confirmButton='Yes'
                cancelButton='No'
                onClose={async ( isRescheduleConfirmed ) => {
                  const slot = openRescheduleDialog;
                  setOpenRescheduleDialog( undefined );
                  if( slot && isRescheduleConfirmed ) {
                    await onReschedule( slot );
                  }
                }}
              />

            </Box>
          }


        </Stack>
      </div>

    </>
  );
}


