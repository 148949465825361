/* eslint @typescript-eslint/no-unused-vars: 'off' */
/* eslint @typescript-eslint/ban-types: "off" */
import { Address, BackboneElement, Coding, ContactPoint, DomainResource, Element as FhirElement, HumanName, Meta } from 'fhir/r4';
import { CopyWithRequired } from './lib/type-helpers';

export type Childless<T extends { children?: unknown }> = Omit<T, 'children'>;

export type OmitByPropertyType<Type, PropertyType> = {
  [ Property in keyof Type as Type[ Property ] extends PropertyType ? never : Property ]: Type[ Property ]
}

export type PickByPropertyType<Type, PropertyType> = {
  [ Property in keyof Type as Type[ Property ] extends PropertyType ? Property : never ]: Type[ Property ]
}

export type OmitFunctions<Type> = OmitByPropertyType<Type, Function>;


export type Existing<
  T extends DomainResource | BackboneElement = DomainResource
> = CopyWithRequired<T, 'id'>;
export type WithoutElement<T extends FhirElement> = Omit<T, keyof FhirElement>;

export type FhirAddress = WithoutElement<Address>;
export type FhirCoding = WithoutElement<Coding>;
export type FhirContactPoint = WithoutElement<ContactPoint>;
export type FhirMeta = WithoutElement<Meta>;
export type FhirHumanName = WithoutElement<HumanName>;


export type IdentifierType = string;
export interface StandardFieldsInterface<T extends IdentifierType = string> {
  _id: T;
  id: string;
  createdAt: string; // Date;
  updatedAt: string; // Date;

}

export interface TagsFieldInterface {
  tags: string[];
}

export interface AssetInterface extends StandardFieldsInterface, TagsFieldInterface {

  subject: string;
  body: string;
  images?: IdentifierType[];

}

export enum ICON_STYLE_ENUM {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BLACK = 'black',
}

export enum AMENITY_LAYOUT_ENUM {
  CARD = 'card',
  ACCORDION = 'accordion',
}

export interface TreeFieldsInterface {
  children: IdentifierType[];
  isRoot: boolean;
  parent: IdentifierType | null;
  root: IdentifierType;
}

export type TagList = IdentifierType[];

export interface CustomizeThemeProps extends Record<string, unknown> {
  fontBody?: string;
  fontHeader?: string;
  colorPrimary?: string;
  colorSecondary?: string;
}

export interface OrganizationStyling extends CustomizeThemeProps {
  iconStyle?: 'default' | 'primary' | 'secondary' | 'black';
  amenityLayout?: 'accordion' | 'card';
  disableCharities?: boolean;
}

export interface OrganizationInterface extends StandardFieldsInterface, TreeFieldsInterface {

  name: string;
  comment?: string;
  telephone?: string;
  email?: string;
  webUrl: string;

  description?: string;
  amenityTags?: TagList,

  colorPrimary?: string;
  colorSecondary?: string;
  fontBody?: string;
  fontHeader?: string;
  iconStyle?: ICON_STYLE_ENUM;
  amenityLayout?: AMENITY_LAYOUT_ENUM;
  disableCharities?: boolean;

  twitter?: string;
  facebook?: string;
}

export enum TransmitMethodEnum {
  UNKNOWN = 'unknown',
  IGNORE = 'ignore',
  SMS_EMAIL = 'sms email',
  SMS = 'sms',
  EMAIL = 'email',
  PAGER = 'pager',
  OTHER = 'other',
  VOICE_SMS_MOBILE = 'voice sms mobile',
  VOICE_LANDLINE = 'voice landline',
  DELETE_ENTRY = 'delete entry',
  UNSUBSCRIBED = 'unsubscribed',
}

export enum TwilioLineType {
  LANDLINE = 'landline',
  MOBILE = 'mobile',
  VOIP = 'voip',
  OTHER = 'other',
  INVALID = 'invalid', // AWS
  PREPAID = 'prepaid', // AWS
}

export interface TelecomOptionInterface extends FhirContactPoint {
  _id?: string;
  invalidValue?: boolean;
  transmitMethod?: TransmitMethodEnum | string;
  dataSource?: string; // only directEntry is editable
  unsubscribe?: boolean;
  unsubscribeDate?: Date | string;
  unsubscribeReason?: string;
  // New with carrier info check 4/21/2021 PL
  forcedUse?: boolean; //Always Use entry
  forcedIgnore?: boolean; //Always Skip this entry
  forcedOverridesDate?: Date | string; // Date this entry put in place

  carrierChecked?: boolean;
  carrierCheckFailed?: boolean;
  carrierCheckedDate?: Date | string;
  carrierDataSupplier?: string;
  carrierName?: string;
  carrierLineType?: TwilioLineType;
  carrierPhoneNumber?: string;
  carrierNationalPhoneNumber?: string;
  carrierCountryCode?: string;

  carrierMobileCountryCode?: string | null; //Added variable to lookup normalized name of carrier - 07/09/2022 PL
  carrierMobileNetworkCode?: string | null; // from third party lookup table.  Used for SMS Email.
}

export enum RecipientTypeEnum {
  UNKNOWN = 'unknown',
  ALL = 'All',
  PATIENT = 'Patient',
  NON_PATIENT = 'Non-Patient',
  FAMILY_MEMBER = 'Family Member',
  EXTENDED_FAMILY_MEMBER = 'Extended Family Member',
  NEIGHBOR = 'Neighbor',
  FRIEND = 'Friend',
  GUARDIAN = 'Guardian',
  CAREGIVER = 'Caregiver',
  TRANSPORTATION = 'Transportation',
  SKILLED_NURSING = 'Skilled Nursing',
  PHYSICIAN = 'Physician',
  ATTORNEY = 'Attorney',
  PRACTITIONER = 'Practitioner',
  OTHER = 'Other',
}

export enum EntryMethodEnum {
  UNKNOWN = 'unknown',
  MANUAL = 'manual',
  SMS = 'sms',
  FHIR_IMPORT = 'fhir import',
  PROVIDER_APP = 'provider app',
  PROFILE_APP = 'profile app',
}

export interface CommChannelsFound {
  annotatedTelecomOptions: ( TelecomOptionInterface | { value?: string; transmitMethod: string } )[];
}

export interface RecipientInterface extends StandardFieldsInterface {
  // fhirId?: string;
  profileId?: string;
  // openEmrId?: string;
  comment?: string;
  active: boolean;
  entryMethod: EntryMethodEnum;
  fhirName: FhirHumanName[];
  realTimeUpdates: boolean; //Set to true with configured telecomOptions to transmit real-time updates
  telecomOptions: TelecomOptionInterface[];
  commChannelsFound?: CommChannelsFound;
  recipientType: RecipientTypeEnum;
  timeZoneName?: string;
  firstName: string;
  middleName?: string;
  givenName?: string;
  lastName: string;
  fullName: string;
  initials?: string;
  prefix?: string;
  suffix?: string;
  // fhirPreSuffix?: boolean;
  // unsubscribeFlag: boolean;
  // unsubscribeDate?: Date;
  // unsubscribeReason?: string;
  // welcomeMessageSent: boolean,
  // welcomeMessageSentDate?: Date
  //  
  // deceasedBoolean: boolean;
  // birthDate?: Date;
  lastLocation?: IdentifierType;
  //  
  recipients: IdentifierType[];
  // botContext?: BotContextInterface;

  lang?: string;
  lang3?: string;
  langs?: string[];
  lang3s?: string[];

  parent?: IdentifierType;

  // Categories
  disableAppointmentReminders?: boolean; // Appointment reminders and confirmations
  disableAppointmentRealTimeUpdates?: boolean; // Appointment real-time updates
  disableLabUpdates?: boolean; //lab and pathology updates
  disableOfficeCommunication?: boolean;
  disablePractitionerCommunication?: boolean; // Thank you, checking on condition
  disablePrePostOpCareInstructions?: boolean;
  disableBirthdays?: boolean; // Birtday greetings from office
  disableMarketingUpdates?: boolean; // marketing updates / promotions
  disableReviewReminders?: boolean;
}

export interface ReputationServiceInterface extends StandardFieldsInterface<string> {
  id: string;
  name: string;
  url: string;
  referralUrl?: string;
  logo?: string;
}

export interface PractitionerInterface extends StandardFieldsInterface, TagsFieldInterface {
  comment: string;
  fhirId: string;
  active: boolean;

  recipientType?: RecipientTypeEnum; // Added 7/4/2022 PL to help identify type of telecom object being annotated

  prefix?: string;
  firstName: string;
  middleName?: string;
  givenName?: string;
  lastName: string;
  suffix?: string;
  fullName: string;

  fhirPreSuffix: boolean;
  npi: string;

  fhirName: FhirHumanName[];
  fhirTelecom: FhirContactPoint[];
  telecomOptions: TelecomOptionInterface[];

  entryMethod: EntryMethodEnum;
  unsubscribeFlag: boolean;
  unsubscribeDate?: Date,
  unsubscribeReason?: string,
  realTimeUpdates: boolean
  welcomeMessageSent: boolean,
  welcomeMessageSentDate?: Date
  recipients: string[];

  reputationServices: ReputationServiceInterface[];
  isInternalReviewOnly?: boolean;
  isExternalReviewEnabled?: boolean;

  profileImageUrl?: string;

  // configuredBySourceType: ConfiguredBySourceTypeEnum; // How is this data configured

  // MFA identity verification
  verifiedByMfa: boolean;
  verifiedByMfaDate?: Date;
  verifiedByMfaMethod?: string;

  // lastEdited: Date;     // Date last Edited
  lastReferenced?: Date; //Last time record was referenced by appointment, patient, or office

  // Returned by annotation scan
  //  commChannelsFound?: CommChannelsFound;

  // Categories
  disableOfficeCommunication?: boolean;
  disableAppointmentRealTimeUpdates?: boolean; // Appointment real-time updates
  disableLabUpdates?: boolean;    //lab and pathology updates

  // Results from telecom scan (was BadTelecoms)
  invalidTelecom?: boolean; // active
  invalidTelecomValue?: boolean; // invalidValue
  noTelecomEntries?: boolean;
  noValidTelecomEntries?: boolean;
  badPhoneNumber?: boolean;
  badPhoneNumbersList?: TelecomOptionInterface[]
  badEmailFormat?: boolean;
  badEmailAddresses?: TelecomOptionInterface[];
  missingSms?: boolean;
  missingEmail?: boolean;
  missingSmsVoice?: boolean;
  missingLandlineVoice?: boolean;
  lastTelecomScanDate?: Date;  // lastScan


  // tags
}

export type PractitionerSummary = Pick<PractitionerInterface, 'id' | 'fullName' | 'profileImageUrl' | 'fhirId' | 'prefix' | 'suffix'>;

export interface AppointmentInterface extends StandardFieldsInterface {

  status: string;
  fhirId: string;

  appointmentTypeCode?: string;
  appointmentTypeDisplay?: string;

  startTime: string;
  endTime?: string;
  patient: string;
  location: string;
  practitioner: string;

  timeZoneName: string;

}

export enum ELOCATION_UPDATE_METHOD {
  EMR_IMPORT = 'imported from emr',
  MANUAL_ENTRY = 'entered manually by human',
  UNKNOWN = 'unknown',
}
export enum ELOCATION_STATUS {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  INACTIVE = 'inactive',
}

export interface GeolocationFieldOptions {
  fieldName?: string;
  required?: boolean;
  index?: boolean;
  addressFieldName?: string;
}

export interface GeoPoint {
  type: 'Point';
  coordinates: number[];
}

export interface AddressPartsInterface {
  unit?: string;
  streetNumber?: string;
  street?: string;
  city?: string;
  county?: string;
  neighborhood?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export interface AddressComponentsInterface {
  long?: AddressPartsInterface;
  short?: AddressPartsInterface;
  formatted?: string;
  lines: string[];
}

export interface GeolocationInterface { // default field name only
  geolocation?: string | GeoPoint;
  addressComponents?: AddressComponentsInterface;
}

export interface WeekdayOpenClose {
  weekday: number;
  openHour?: number;
  openMinute?: number;
  closeHour?: number;
  closeMinute?: number;
}

export type WeekdaysHours = WeekdayOpenClose[]

export interface WeekdaysHoursInterface { // default field name only
  weekdaysHours?: WeekdaysHours;
  formattedHours?: string;
}

// export interface GoogleDataInterface {
//   tzId?: string;
//   tzName?: string;
//   coord: {
//     lat: number;
//     lng: number;
//   };
//   tzResults?: TimeZoneResult,
//   gpsResults?: GoogleGeocodeResult,
// };
//  
// export interface GeoDataInterface
//   extends Omit<GoogleDataInterface, 'tzId' | 'tzName' | 'coord'> {
//   refAddress?: string;
// }
//  
// export const GeoDataSchema: Schema = new Schema<GeoDataInterface>({
//   refAddress: String,
//   gpsResults: Object, // TODO
//   tzResults: Object,
// });

export interface PractitionerReputationServiceInterface extends StandardFieldsInterface<string> {
  _id: string; // ReputationService has string ids
  externalId: string;
}

export interface LocationInterface extends StandardFieldsInterface, WeekdaysHoursInterface, TagsFieldInterface, GeolocationInterface {
  comment?: string;
  name: string;
  nickname?: string;
  slug?: string;
  status?: ELOCATION_STATUS;
  fhirId: string;
  lastUpdateMethod?: ELOCATION_UPDATE_METHOD;
  fhirAddress?: FhirAddress[];
  fhirAddressId?: string;
  humanAddress?: string;
  telephone?: string;
  fax?: string;
  email?: string;
  webUrl?: string;
  webShortUrl?: string;
  logoUrl?: string;
  amenityUrl?: string;
  amenityShortUrl?: string;
  locationSignature?: string;
  timeZoneName?: string;

  // Coordinates plugin?
  lat?: number;
  lng?: number;

  // weekdaysHours plugin

  geoCoded?: boolean;
  timeZoneHumanName: string;
  // googleGeoData?: GeoDataInterface;

  reputationServices: PractitionerReputationServiceInterface[];

  // new from org-api/amenities
  description?: string;
  organization?: string;
  // geolocation
  // tags

  twitter?: string;
  facebook?: string;

  locationEnabledForMessageSend?: boolean;
  locationEnabledForReviewGating?: boolean;

  appointmentTypeCodesEnabledForCancel?: string[];
  appointmentTypeCodesEnabledForReschedule?: string[];
  appointmentTypeCodesEnabledForSchedule?: string[];
}

export enum FeedbackStatusEnum {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
}

export interface FeedbackInterface extends StandardFieldsInterface {

  status: FeedbackStatusEnum;

  recipient: string;
  recipientType: RecipientTypeEnum;
  location: string;
  practitioner?: string;
  appointment?: string;

  pledgeId?: string;

  reputationUrls?: string[];

  // submissions: Record<string, unknown>[];
}

export enum TagTypeEnum {
  Common = 'common',
  Resource = 'resource',
  Location = 'location',
  Organization = 'organization',
  Practitioner = 'practitioner',
}

export interface TagInterface extends StandardFieldsInterface, GeolocationInterface {
  name: string;
  type?: TagTypeEnum[];
}
